@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.link {
  display: inline-block;
  white-space: nowrap;

  /* Font */
  font-weight: var(--Med--fontWeightMediumhtSemiBold);
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.25px;

  color: var(--matterColorLight);
  padding-bottom: 10px;
  padding-top: 10px;

  /* push tabs against bottom of tab bar */
  margin-top: auto;

  transition: var(--transitionStyleButton);

  &:hover {
    text-decoration: none;
    color: var(--matterColorDark);
  }

  @media (--viewportLarge) {
    font-size: 18px;
    line-height: 24px;

    width: var(--TabNav_linkWidth);
    border-bottom-width: 0px;
    margin-top: 0;
    margin-bottom: 16px;

    padding-top: 6px;
    /* Drop the text to adjust it to correct baseline */
    padding-bottom: 2px;
  }
}

.selectedLink {
  color: var(--matterColorDark);

  @media (--viewportLarge) {
    border-right: 5px solid #fff;
    width: var(--TabNav_linkWidth);
  }
}

.disabled {
  pointer-events: none;
  color: #fff;
  text-decoration: none;
}