@import '../../styles/customMediaQueries.css';

.containerPrincipal {
  display: flex;
  justify-content: center;

  @media (--viewportLarge) {
    padding-bottom: 6rem;
  }
}

.containerColumns {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;

  @media (--viewportLarge) {
    grid-template-columns: 1fr 1fr;
  }
}

.firstColumn {
  padding: 64px 28px;
  background-color: var(--marketplaceColor);
  text-align: center;

  @media (--viewportLarge) {
    text-align: left;
    padding: 5rem 4rem;
  }
}

.secondColumn {
  flex-basis: 100%;
  background: url('./img/ÛUSEGURO_image.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 50vh;

  @media (--viewportLarge) {
    flex: 1;
    height: auto;
  }
}

.title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 4.9px;
  margin-bottom: 24px;
  color: var(--matterColorLight);

  @media (--viewportLarge) {
    font-size: 16px;
  }

  @media (--viewportXLarge) {
    font-size: 20px;
    letter-spacing: 7px;
  }
}

.subTitle {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: normal;
  margin-bottom: 24px;
  text-align: center;
  color: var(--matterColorLight);

  @media (--viewportLarge) {
    text-align: left;
    font-size: 40px;
    text-align: left;
  }

  @media (--viewportLargeWithPaddings) {
    text-align: left;
    line-height: 1.2;
  }
}

.text {
  color: var(--matterColorLight);
  line-height: 1.4;
  font-size: 16px;
  font-weight: 500;

  @media (--viewportXLarge) {
    text-align: left;
    font-size: 24px;
    text-align: left;
    line-height: normal;
  }
}

.line {
  width: 100%;
  height: 2px;
  background-color: white;
  margin: 2rem 0;

  @media (--viewportLargeWithPaddings) {
    width: 90%;
  }
}


.twoButton {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;
  margin-bottom: 24px;
  border-radius: 10px;
  color: var(--matterColorLight);
  margin-top: 2rem;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid white;
  margin: 0 auto;

  @media (--viewportMedium) {
    margin-top: 0;
    line-height: normal;
  }

  @media (--viewportLarge) {
    font-size: 16px;
    margin: 0;
  }
}

.twoButton:focus,
.twoButton:hover {
  background-color: var(--successColorDark);
  border: 1px solid var(--successColorDark);
}