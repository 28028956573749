@import '../../../../styles/customMediaQueries.css';

.card {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 24px;
}


.text {
    color: var(--matterColorLight);
    margin: 0;

    @media (--viewportMedium) {
        min-height: 160px;
    }
}

.imageContainer {
    width: fit-content;
    display: grid;
    place-items: center;
    background-color: var(--marketplaceColorDark);
    border-radius: 50%;
    padding: 24px;
}

.image {
    max-width: 100px;
    background-color: var(--colorWhite);
}

.title {
    composes: h2 from global;
    margin: 0;
    color: var(--matterColorLight);
    font-weight: var(--fontWeightBold);

    @media (--viewportMedium) {
        min-height: 68px;
    }
}