@import '../../styles/customMediaQueries.css';

.root {
    width: 100%;
    background-color: var(--matterColorLight);
    padding: 64px 28px;

    @media (--viewportLarge) {
        padding: 6rem 4rem;
    }
}

.mainTtitle {
    padding-bottom: 32px;
    width: 100%;
    text-align: center;

    @media (--viewportMedium) {
        width: fit-content;
        text-align: unset;
    }
}

.line {
    display: flex;
    width: 35%;
    margin: auto;
    height: 15px;
    background-color: var(--marketplaceColor);
    margin-top: -20px;

    @media (--viewportMedium) {
        margin: auto 0 auto auto;
        height: 25px;
        width: 30%;
        margin-top: -17px;
    }
}

.container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    margin: auto;
    gap: 32px;

    @media (--viewportMedium) {
        grid-template-columns: 1fr 1fr 1fr;
    }

}

.mainTtitle {
    color: var(--matterColorDark);
    font-weight: var(--fontWeightBold);
    font-size: 32px;
}

.card {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.title {
    margin: 0;
    color: var(--matterColorDark);
    font-weight: var(--fontWeightMedium);
}

.text {
    color: var(--matterColorDark);
    margin: 0;
}

.link {
    text-decoration: underline;
}

.butonContainer {
    margin-top: 48px;
}

.button {
    composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;
}