@import '../../styles/customMediaQueries.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }

  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 3s;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  /* composes: defaultBackgroundImage from global; */
  display: flex;
  flex-direction: column;
  width: auto;

  /* Text positioning is done with paddings */
}

.heroContent {
  display: flex;
  padding: 0 4rem;
  position: relative;

  @media (--viewportMedium) {}

  @media (--viewportLarge) {
    padding: 0 24px;
    max-width: 1824px;
    display: flex;
    flex-direction: row;
  }

  /* Special viewport for adjusting the heroContent's placement */
}

.containerArrow {
  @media (--viewportLargeWithPaddings) {
    display: flex;
    flex-direction: column;

    position: relative;
    margin-right: 40px;

  }
}

.arrowLeft {
  display: none;

  @media (--viewportLargeWithPaddings) {
    display: block;
    width: 17px;
    position: absolute;
    bottom: 14%;
  }
}

.containerText {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.heroMainTitle {
  composes: marketplaceHeroTitleFontStyles from global;
  color: var(--matterColorLight);
  composes: animation;
  font-family: Montserrat;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }
}

.heroMainTitleFEDelay {
  animation-delay: 0s;
}

.heroSubTitle {
  composes: animation;
  color: var(--matterColorLight);
  margin: 0 0 32px 0;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 120%;
  letter-spacing: normal;

  @media (--viewportSmall) {
    font-size: 18px;
  }

  @media (--viewportMedium) {
    font-size: 20px;
    margin: 0 0 47px 0;
  }

  @media (--viewportLarge) {
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
  }
}

.heroSubTitleFEDelay {
  animation-delay: 0.15s;
}

.heroButton {
  composes: button buttonFont buttonText buttonBorders from global;
  composes: animation;
  margin-bottom: 24px;
  border-radius: 10px;
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;

  @media (--viewportMedium) {
    display: block;
  }
}

.heroButton:focus,
.heroButton:hover {
  background-color: var(--successColorDark);
  color: #fff;
}

.heroButtonFEDelay {
  animation-delay: 0.3s;
}

.contentText {
  width: 100%;

  @media (--viewportLarge) {
    margin-left: 30px;
    display: flex;
    flex-direction: row;
  }
}

.contentImg {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    align-items: center;
    padding-left: 5rem;
  }
}

.contentImg iframe {
  border-radius: var(--borderRadius);
  width: 530px;
  height: 315px;

  @media (--viewportLargeWithPaddings) {
    width: 580px;
    height: 315px;
  }
}

.bannerHome {
  @media (--viewportLarge) {
    width: 50vw;
    height: 60vh;
    margin-left: auto;
  }
}