.root {
  position: relative;
  padding-top: 8px;
  padding-bottom: 14px;
  border-bottom: 1px solid var(--matterColorNegative);
}

.filterLabel,
.filterLabelSelected {
  composes: h3 from global;

  /* Baseline adjustment for label text */
  margin-top: 0;
  margin-bottom: 10px;
}

.filterLabel {
  color: var(--matterColorDark);
  font-size: 16px;
  font-weight: var(--Med--fontWeightMediumhtSemiBold);
}

.filterLabelSelected {
  color: var(--marketplaceColor);
}

.labelButton {
  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
}

.clearButton {
  font-size: 14px;
  font-weight: var(--Med--fontWeightMediumhtSemiBold);
  color: var(--matterColorAnti);

  /* Layout */
  display: inline;
  float: right;
  margin-top: 6px;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }
}
