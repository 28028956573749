@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 35px;

}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
}

.emailLink {
  composes: marketplaceModalHelperLink from global;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.emailText {
  composes: marketplaceModalHelperText from global;
}


.smsCode {
  margin-top: 20px;
  margin-bottom: 40px;
}

.error {
  margin-top: 10px;
  margin-bottom: 10px;
}

.submitButton {
  min-width: 100px;
  text-transform: unset;
  width: 100%;
  height: 50px;
}