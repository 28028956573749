@import '../../styles/customMediaQueries.css';

.root {
  margin: 0;
  padding: 0;
  border: none;
}

.list {
  margin: 0;
}

.twoColumns {
  @media (--viewportMedium) {
    columns: 2;
  }
}

.checkboxInput {
  @media (--viewportMedium) {
    display: flex;
    flex-wrap: wrap;
  }
}

.item {
  padding: 2px 0;
  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 4px 0;
    width: 50%;
  }
}


