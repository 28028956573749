@import '../../styles/customMediaQueries.css';

.root {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);

  width: 100%;
  padding: 12px 12px 42px 12px;
  background-color: #f5f5f5;

  @media (--viewportMedium) {
    padding: 0 24px 33px 24px;
  }

  @media (--viewportLarge) {
    padding: 50px 24px 81px 24px;
    border-top: none;
  }
}

/* Wrapper to limit the top border in desktop
 * view from the outermost paddings*/
/* .topBorderWrapper {
  @media (--viewportMedium) {
    padding-top: 41px;
  }

  @media (--viewportLarge) {
    padding-top: 73px;

    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: var(--matterColorNegative);
  }
} */

.content {
  display: flex;
  flex-direction: column;
  max-width: 1824px;
  padding: 2rem 0rem;

  @media (--viewportMedium) {
    margin: 0 auto;
    padding: 2rem;
  }

  @media (--viewportLarge) {
    padding: 3rem;
  }
}

/* Mobile only social media links */
.someLiksMobile {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.icon {
  display: inline-block;
  width: 20px;
  height: 24px;
  margin-right: 12px;
  text-align: center;
}

/* Footer links */
.links {
  /* Layout */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 0;
  }
}

/* Desktop: logo and organization stuff */
.organization {
  flex-shrink: 0;
  flex-basis: 220px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: block;
    order: 1;
    margin-right: 12px;
  }

  @media (--viewportLarge) {
    flex-basis: 240px;
    margin-right: 57px;
  }

  @media (--viewportLargeWithPaddings) {
    flex-basis: 250px;
    margin-right: 12px;
  }
}

.image {
  width: 100%;
}

.enlaceEmail {
  color: var(--matterColorDark);
  font-weight: 600;
  margin-right: 1rem;
  font-family: Montserrat;

  @media (--viewportXLarge) {
    font-size: 19px;
  }
}

.enlacePage {
  color: var(--matterColorDark);
  font-weight: 400;
  margin-right: 1rem;
  font-family: Montserrat;

  @media (--viewportXLarge) {
    font-size: 18px;
  }
}

.enlaceEmail:hover,
.enlacePage:hover {
  text-decoration: none;
  color: #92c198;
}

/* Desktop: extra links (social media links and legal links) */
.linksRedes {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex: 100%;
  order: 2;
  margin-bottom: 24px;
  text-align: center;
  border-bottom: 1px solid gray;
  padding-bottom: 2rem;
  
  @media (--viewportMedium) {
    display: flex;
    justify-content: space-between;
    flex: 1 1 0;
    text-align: end;
    padding-bottom: 1rem;
    margin-left: 3rem;
  }
  
  @media (--viewportLarge) {
    margin-right: 0;
  }
  @media (--viewportLargeWithPaddings) {
    flex-direction: row;
  }
}

.linksFooter {
  display: flex;
  flex-direction: column;
  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.privacy,
.terms {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Mobile: copyright mark and legal links for mobile */
.copyrightAndTermsMobile {
  /* Positioning */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  /* Dimensions */
  height: 60px;
  padding: 14px;
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #d7d7d7;
  font-family: Montserrat;
  color: var(--matterColorDark);

  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 14px 30px;
    background-color: var(--matterColorLight);
  }
}

.organizationCopyrightMobile {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  /* Tell parent component how much space this one prefers */
  flex-basis: 172px;

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.privacy {
  font-size: 9px;
  margin: 0 1px;
  color: var(--matterColorDark);

  @media (--viewportSmall) {
    font-size: 14px;
    margin: 0 12px;
  }
}

.copyrightAndTermsMobile span {
  font-size: 9px;

  @media (--viewportSmall) {
    font-size: 14px;
  }
}