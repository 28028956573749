@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--matterColorDark);
}

.policy {
  composes: formMargins;
  flex-shrink: 0;
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin-top: 25px;
  margin-bottom: 24px;
  flex-shrink: 0;
  width: 100%;
  letter-spacing: -0.27px;
  text-transform: none;
  font-weight: var(--Med--fontWeightMediumhtSemiBold);
  background-color: var(--matterColorDark);
  @media (--viewportLarge) {
    display: inline-block;
    width: 50%;
  }
}

.submitButton:focus,
.submitButton:hover {
  background-color: var(--successColorDark);
  color: var(--matterColorLight);
}
