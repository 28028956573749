@import '../../styles/customMediaQueries.css';

.root {
}

.icon {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-bottom: 36px;
  }
}

.heading {
  font-size: 28px;
  margin-top: 0;
}

.field {
  width: 100%;

  @media (--viewportMedium) {
    width: 70%;
    margin: auto;
  }
}

.error {
  color: var(--failColor);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}
