@import '../../styles/customMediaQueries.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }

  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 3s;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  /* composes: defaultBackgroundImage from global; */
  display: flex;
  flex-direction: column;
  width: auto;
  width: 100%;
  /* Text positioning is done with paddings */
}

.heroContent {
  padding: 4rem 2rem 0 2rem;

  @media (--viewportMedium) {
    padding: 0 4rem 0 4rem;
  }
}

.containerArrow {
  @media (--viewportLargeWithPaddings) {
    display: flex;
    flex-direction: column;

    position: relative;
    margin-right: 40px;

  }
}

.arrowLeft {
  display: none;

  @media (--viewportLargeWithPaddings) {
    display: block;
    width: 17px;
    position: absolute;
    bottom: 14%;
  }
}

.containerText {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.heroMainTitle {
  composes: marketplaceHeroTitleFontStyles from global;
  color: var(--matterColorLight);
  composes: animation;
  font-family: Montserrat;
  font-weight: 800;
  line-height: 120%;


  @media (--viewportLarge) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    line-height: 70px;
  }
}

.heroMainTitleFEDelay {
  animation-delay: 0s;
}

.heroSubTitle {
  font-size: 18px;
  color: var(--matterColorLight);
  margin: 0 0 32px 0;
  composes: animation;
  font-family: Montserrat;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopSubTitleMaxWidth);
    margin: 0 0 47px 0;
  }

  @media (--viewportLarge) {
    font-size: 24px;
  }
}

.heroSubTitleFEDelay {
  animation-delay: 0.15s;
}

.heroButton {
  composes: button buttonFont buttonText buttonBorders from global;
  composes: animation;
  margin-bottom: 24px;
  border-radius: 10px;
  background-color: #fff;
  color: var(--successColorDark);

  @media (--viewportMedium) {
    display: block;
  }
}

.heroButton:focus,
.heroButton:hover {
  background-color: var(--successColorDark);
  color: #fff;
}

.heroButtonFEDelay {
  animation-delay: 0.3s;
}

.heroButton2 {
  composes: button buttonFont buttonText buttonBorders from global;
  composes: animation;
  margin-bottom: 24px;
  color: var(--matterColorLight);
  background-color: black;

  @media (--viewportMedium) {
    display: block;
  }
}

.heroButton2:focus,
.heroButton2:hover {
  background-color: var(--successColorDark);
  color: var(--matterColorLight);
}

.heroButton2FEDelay {
  animation-delay: 0.6s;
}

.contentText {
  width: 100%;

  @media (--viewportLarge) {
    /* width: 30%; */
    margin-left: 30px;
    display: flex;
    flex-direction: row;
  }
}

.contentImg {
  display: none;

  @media (--viewportLarge) {
    width: 70%;
    padding-left: 110px;
    display: flex;
    align-items: center;
  }
}

.bannerHome {
  @media (--viewportLarge) {
    width: 85%;
    margin-left: auto;
  }

  @media (min-width: 1440px) {
    width: 100%;
  }
}