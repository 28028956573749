.icon {
  height: 20px;
  width: auto;
  margin: 0 auto;
  margin-right: 5px;
  cursor: pointer;

  @media (max-width: 768px) {
    margin: 0 20px;
  }

  &:hover {
    transform: scale(1.05);
  }
}

.menu {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
}

.langSwitchWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-bottom: 3px;
  height: 100%;
  margin: 0 15px;
}

.langSwitchWrapperMobile {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-bottom: 3px;
  height: 100%;
  margin: 0 15px;
  width: 20px;
}

.labelWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
  height: 45px;
  width: 45px;
  margin: 6px;

  @media (max-width: 768px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    &:hover {
      background-color: var(--marketplaceColorLight);
    }
  }
}

.mobileLabel {
  display: block;
  cursor: pointer;
  /* @media (min-width: 768px) {
    display: none;
  } */
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}


.profileMenuContentMobile {
  max-width: 80px;
  padding-top: 20px;
  margin-left: 65vw;
  margin-top: 10px;
}