@import '../../styles/customMediaQueries.css';

.contentSection {
  background: #fff;
}

.threeContent {
  max-width: 1824px;
  margin: auto;
  padding: 64px 28px;
  text-align: center;
  background-color: white;
  @media (--viewportLarge) {
    padding: 5% 28px;
  }
}

.title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 4.9px;
  margin-bottom: 24px;
  color: var(--matterColorDark);
  font-family: Montserrat;
  @media (--viewportLarge) {
    font-size: 20px;
    letter-spacing: 7px;
  }
}

.subtitle {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: normal;
  padding-bottom: 5rem;
  font-family: Montserrat;
  color: var(--matterColorDark);
  @media (--viewportMedium) {
    font-size: 24px;
    line-height: 1.33;
  }
  @media (--viewportLarge) {
    font-size: 32px;
    line-height: normal;
    letter-spacing: normal
  }
}

.triangulo {
  width: 0;
  height: 0;
  border-right: 20px solid transparent;
  border-top: 20px solid transparent;
  border-left: 20px solid transparent;
  border-bottom: 20px solid var(--marketplaceColor);
  margin: auto;
  margin-bottom: 2rem;
}

.line {
  display: flex;
  width: 95%;
  height: 15px;
  margin: auto;
  background-color: var(--marketplaceColor);
  margin-top: -15px;
  @media (--viewportSmall) {
    width: 75%;
  }
  @media (--viewportMedium) {
    width: 45%;
  }
  @media (--viewportLarge) {
    width: 35%;
    height: 25px;
    margin-top: -23px;
  }
}

.sectionBackground {
  background: linear-gradient(180deg, rgba(187,187,187,0.5074404761904762) 0%, rgba(255,255,255,1) 80%);
  padding-bottom: 7%;
}

.contentCards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 27px;
  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: center;
    gap: 5%;
  }
}

.contentCard {
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;
  height: 144px;
  padding: 3rem 1rem;
  border-radius: 10px;
  box-shadow: 10px 10px 28px 0 rgba(0, 0, 0, 0.15);
  line-height: 1.4;
  font-weight: 400;
  @media (--viewportLarge) {
    width: 420px;
    height: 160px;
    padding: 3rem 2rem;
    line-height: 25.2px;
  }
  @media (min-width: 1440px) {
    width: 600px;
    height: 230px;
    padding: 1rem 4rem;
    font-size: 18px;
  }
}

.image {
  width: 20%;
  margin-right: 2rem;
  @media (--viewportMedium) {
    width: 12%;
  }
  @media (--viewportLarge) {
    width: 30%;
  }
}
