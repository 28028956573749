@import '../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 11px 24px 0 24px;
}

.form {
  flex-grow: 1;
}

/* ================ Modal ================ */

.modalHeaderWrapper {
  margin-top: 58px;
  margin-bottom: 36px;
  padding: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 34px;
    padding: 0;
  }
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

/* This is the title for the Edit Photos component */
.title {
  margin-bottom: 19px;
  color: var(--matterColorDark);
  font-size: 18px;
  font-weight: var(--Med--fontWeightMediumhtSemiBold);
  @media (--viewportMedium) {
    font-size: 30px;
  }
  @media (--viewportXLarge) {
    margin-bottom: 17px;
    padding: 1px 0 7px 0;
    font-size: 40px;
    line-height: 56px;
    letter-spacing: -1.25px;
  }
}
