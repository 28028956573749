@import '../../styles/customMediaQueries.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }

  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 3s;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  /* composes: defaultBackgroundImage from global; */
  display: flex;
  flex-direction: column;
  width: auto;

  /* Text positioning is done with paddings */
}

.heroUuperContent {
  padding: 4rem 2rem 0 2rem;

  @media (--viewportMedium) {
    padding: 0 4rem 0 4rem;
  }

  /* Special viewport for adjusting the heroContent's placement */
}

.heroUuperMainTitle {
  composes: marketplaceHeroTitleFontStyles from global;
  composes: animation;
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 120%;
  letter-spacing: normal;
  color: var(--matterColorLight);

  @media (--viewportLarge) {
    font-size: 60px;
    line-height: normal;
  }
}

.heroUuperMainTitleFEDelay {
  animation-delay: 0s;
}

.heroUuperSubTitle {
  composes: h1 from global;
  composes: animation;
  color: var(--matterColorLight);
  margin: 0 0 32px 0;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 120%;
  letter-spacing: normal;

  @media (--viewportLarge) {
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
  }
}

.heroUuperSubTitleFEDelay {
  animation-delay: 0.15s;
}

.contentButtons {
  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.heroUuperButton {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;
  composes: animation;
  margin-bottom: 24px;
  border-radius: 10px;

  @media (--viewportLarge) {
    display: block;
    margin-right: 2rem;
  }
}

.heroUuperButton:focus,
.heroUuperButton:hover {
  background-color: var(--successColorDark);
  color: #fff;
}

.heroUuperButtonFEDelay {
  animation-delay: 0.3s;
}

.heroUuperButton2 {
  composes: button buttonFont buttonText buttonBorders from global;
  composes: animation;
  margin-bottom: 24px;
  border: 2px solid var(--matterColorLight);
  color: var(--matterColorLight);

  @media (--viewportLarge) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.heroUuperButton2:focus,
.heroUuperButton2:hover {
  background-color: var(--successColorDark);
  color: var(--matterColorLight);
}

.heroUuperButton2FEDelay {
  animation-delay: 0.6s;
}