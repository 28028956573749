@import '../../styles/customMediaQueries.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }

  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 3s;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.uuperContent {
  /* padding-bottom: 6rem; */
  display: flex;
  justify-content: center;
  background-color: #F5F5F5;
}

.containerUuper {
  display: flex;
  flex-wrap: wrap;
  width: 1430px;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.contentImgUuper {
  border-radius: 10px;
  flex-basis: 100%;
  /* background: url('../../assets/apreton.svg'); */
  background: url('./images/RedFold_image.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 50vh;

  @media (--viewportMedium) {
    flex: 2;
    height: auto;
  }
}

.contentTextUuper {
  flex-basis: 100%;
  padding: 5rem 2rem;
  background-color: #F5F5F5;
  text-align: center;

  @media (--viewportMedium) {
    flex: 1;
    text-align: left;
    padding: 5rem 1rem 5rem 2rem;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 4rem;
  }

  @media (--viewportXLarge) {
    padding: 9rem 4rem 9rem 5rem;
  }
}

.subtitleTwoUupo {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--matterColorDark);
  margin-top: 2rem;

  @media (--viewportMedium) {
    line-height: 1.2;
    font-size: 26px;
  }

  @media (--viewportLargeWithPaddings) {
    font-size: 28px;
  }

  @media (min-width: 1800px) {
    font-size: 32px;
    line-height: 1.2;
  }
}

.textTwoUupo {
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  letter-spacing: normal;
  color: var(--matterColorDark);
  margin: 1rem 0;

  @media (--viewportLargeWithPaddings) {
    font-size: 18px;
  }
}

.textOneUupo {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 7px;
  color: var(--matterColorDark);
  margin: 1rem 0;

  @media (--viewportLargeWithPaddings) {
    font-size: 16px;
  }

  @media (min-width: 1800px) {
    font-size: 20px;
    line-height: 1.5;
  }
}

.twoButton {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;
  margin-bottom: 24px;
  border-radius: 10px;
  color: #fff;
  margin-top: 2rem;

  @media (--viewportMedium) {
    margin-right: auto;
    line-height: normal;
  }
}


.twoButtonFEDelay {
  animation-delay: 0.3s;
}


.SectionTwoUupoMobile {}