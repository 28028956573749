@import '../../styles/customMediaQueries.css';

.howWorksContent {
  max-width: 1824px;
  margin: auto;
  padding: 64px 28px;
  @media (--viewportLarge) {
    padding: 6rem 5rem;
  }
}

.title {
  composes: h2 from global;
  margin-top: 0;
  text-align: center;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  color: var(--matterColorDark);
  letter-spacing: normal;
  @media (--Large) {
    line-height: 46px;
    text-align: initial;
  }
  @media (--viewportLargeWithPaddings) {
    font-size: 32px;
  }
}

.steps {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  text-align: center;
  @media (--viewportMedium) {
    text-align: initial;
    flex-direction: row;
  }
}

.step {
  flex-basis: 100%;
  margin-bottom: 2rem;
  @media (--viewportMedium) {
    flex: 1;
    margin: 0 1rem;
  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepTitle {
  font-size: 18px;
  font-weight: 600;
  margin-top: 18px;
  margin-bottom: 18px;
  color: var(--matterColorDark);
  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
  }
  @media (--viewportXLarge) {
    font-size: 24px;
    margin-top: 21px;
    margin-bottom: 18px;
  }
}

.stepSubtitle {
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: var(--matterColorDark);
  @media (--viewportLarge) {
    font-size: 18px;
    margin-top: 21px;
    margin-bottom: 18px;
    line-height: 25.2px;
  }
}

.createListingLink {
  margin-top: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.line {
  background-color: #92c198;
  height: 2px;
}
.image {
  width: 60px;
  height: 60px;
}
