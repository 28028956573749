@import '../../styles/customMediaQueries.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }

  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 3s;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.contailerTitle {
  text-align: center;
  padding-top: 8%;
  padding-bottom: 11%;
}

.title {
  composes: h1 from global;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  line-height: normal;
  letter-spacing: 7px;
  margin-bottom: 24px;

  @media (--viewportSmall) {
    font-size: 20px;
  }
}

.titleFEDelay {
  animation-delay: 0s;
}

.subtitle {
  font-size: 25px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;

  @media (--viewportSmall) {
    font-size: 40px;
  }
}

.subtitleFEDelay {
  animation-delay: 0.15s;
}

.containerCard {
  background-color: #d7d7d7;
  padding: 4%;
}

.locations {
  display: flex;
  flex-direction: column;
  margin-top: 0px;

  margin-top: 33px;
  margin: auto;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: -11%;
    margin-bottom: 30px;
    max-width: 750px;
  }

  @media (--viewportLarge) {
    max-width: 1000px;
  }

  @media (--viewportXLarge) {
    max-width: 1800px;
  }
}

.location {
  width: 100%;
  margin-top: 25px;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-right: 40px;
    margin-bottom: 0;
  }
}

.location:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 4px;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%);
  /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%);
    /* 3:2 Aspect Ratio */
  }
}

.locationImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  /* border-radius: 4px; */
  object-fit: cover;

  @media (--viewportMedium) {
    border-radius: 10px;
  }
}

.line {
  display: flex;
  width: 95%;
  height: 25px;
  margin: auto;
  background-color: #91c097;
  margin-top: -23px;

  @media (--viewportSmall) {
    width: 75%;
  }

  @media (--viewportMedium) {
    width: 45%;
  }

  @media (--viewportLarge) {
    width: 25%;
  }
}

.oneButton {
  composes: button buttonFont buttonText buttonBorders from global;
  margin-bottom: 24px;
  border-radius: 10px;
  background-color: #92c198;
  color: #fff;
  margin: auto;
  margin-top: 2rem;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.oneButton:focus,
.oneButton:hover {
  background-color: #fff;
  color: #92c198;
}

.oneButtonFEDelay {
  animation-delay: 0.3s;
}

.triangulo {
  width: 0;
  height: 0;
  border-right: 20px solid transparent;
  border-top: 20px solid transparent;
  border-left: 20px solid transparent;
  border-bottom: 20px solid #92c198;
  margin: auto;
  margin-bottom: 2rem;
}