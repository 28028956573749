@import '../../styles/customMediaQueries.css';

.root {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    margin: auto;
    padding: 64px 28px;
    gap: 32px;
    background-color: var(--marketplaceColor);


    @media (--viewportMedium) {
        grid-template-columns: 1fr 1fr;

        background: url('./images/image3.png');
        background-size: cover;
        background-position: bottom center;
    }

    @media (--viewportLarge) {
        min-height: 750px;
        grid-template-columns: 1.5fr 1fr 1fr 1fr;
        padding: 6rem 4rem;
    }
}

.courtainMobile {
    background: url('./images/image3-mobile.png');
    background-size: cover;
    background-position: bottom center;
    height: 50px;

    @media (--viewportMedium) {
        display: none;
    }
}

.card {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
}

.mainTitle {
    color: var(--matterColorLight);
    font-size: 40px;
    /* margin-bottom: 60px; */
    font-weight: 800;
}

.text {
    composes: marketplaceBodyFontStyles from global;
    font-size: 18px;
    color: var(--matterColorLight);
    margin: 0;
}

.buttonsContainer {
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
}

.button {
    color: var(--matterColorLight);
    padding: 12px;
    font-size: 18px;
    line-height: 20px;
    font-weight: var(--fontWeightBold);
    background-color: var(--marketplaceColor);
    border: 2px solid var(--matterColorLight);
    border-radius: 23px;
    transition: var(--transitionStyleButton);
    cursor: pointer;

    &:hover {
        background-color: var(--matterColorLight);
        color: var(--marketplaceColor);
    }
}

.active {
    background-color: var(--matterColorLight);
    color: var(--marketplaceColor);
}