@import '../../styles/customMediaQueries.css';

.root {
    width: 100%;
    background-color: var(--matterColorLight);
    padding: 0 28px 64px 28px;

    @media (--viewportLarge) {
        padding: 0 4rem 6rem 4rem;
    }
}

.mainTtitle {
    padding-bottom: 32px;
}

.container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    margin: auto;
    gap: 32px;

    @media (--viewportMedium) {
        grid-template-columns: 1fr 1fr 1fr;
    }

}

.mainTtitle {
    color: var(--matterColorDark);
    font-weight: var(--fontWeightBold);
    font-size: 32px;
}

.card {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 32px;

    border-radius: var(--borderRadius);
    box-shadow: 10px 10px 28px 0 rgba(0, 0, 0, 0.15);
    transition: var(--transitionStyleButton);

    &:hover {
        transform: scale(1.02);
    }
}

.image {
    width: 48px;
    height: auto;
}

.title {
    composes: h2 from global;
    margin: 0;
    color: var(--matterColorDark);
    font-weight: var(--fontWeightMedium);
}

.text {
    color: var(--matterColorDark);
    margin: 0;
}