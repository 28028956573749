@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Display format */
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--attentionColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: #fff;
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.building,
.medida {
  flex-shrink: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.building label,
.locationAddress label,
.medida label {
  font-size: 16px;
}

.medida {
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (--viewportSmall) {
    width: 50%;
  }
}

.titleMedidas {
  margin-top: 32px;
}

.medida input {
  text-align: center;
}

.submitButton {
  margin-top: 25px;
  margin-bottom: 24px;
  flex-shrink: 0;
  width: 100%;
  letter-spacing: -0.27px;
  text-transform: none;
  font-weight: var(--Med--fontWeightMediumhtSemiBold);
  background-color: var(--matterColorDark);
  @media (--viewportLarge) {
    display: inline-block;
    width: 50%;
  }
}

.submitButton:focus,
.submitButton:hover {
  background-color: var(--successColorDark);
  color: var(--matterColorLight);
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--matterColorDark);
}

.predictionsRoot li {
  color: #1e1e1e;
  &:hover {
    cursor: pointer;
    border-left: 6px solid var(--successColorDark);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    color: var(--successColorDark);
  }
}