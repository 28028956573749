@import '../../styles/customMediaQueries.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }

  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 3s;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.containerColumns {
  display: grid;
  grid-template-columns: 1fr;
  background-color: var(--marketplaceColor);

  @media (--viewportLarge) {
    grid-template-columns: 1fr 1fr;
    background: linear-gradient(90deg, rgba(145, 216, 174, 1) 100%);
  }
}

.contentColumns {
  position: relative;
  padding: 3rem 4rem 3rem 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (--viewportLarge) {
    padding: 3rem 4rem;
  }

  @media (--viewportXLarge) {
    padding: 5rem 12rem;
  }
}

.firstColumn {
  text-align: left;
  padding-left: 4rem;

  @media (--viewportLarge) {
    text-align: left;
    padding-left: 80px;
  }
}

.secondColumn {
  flex-basis: 100%;
  background: url('./img/STREET_image.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom right;
  height: 40vh;

  @media (--viewportMedium) {
    background-position: bottom center;
    height: 50vh;
  }
}

.overlayFilter {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;

  /* Safari */
  background: -webkit-linear-gradient(90deg, rgba(145, 216, 174, 1) 0%, rgba(145, 216, 174, 0) 100%);
  /* Firefox */
  background: -moz-linear-gradient(90deg, rgba(145, 216, 174, 1) 0%, rgba(145, 216, 174, 0) 100%);
  /* Opera */
  background: -o-linear-gradient(90deg, rgba(145, 216, 174, 1) 0%, rgba(145, 216, 174, 0) 100%);
  /* Standard */
  background: linear-gradient(90deg, rgba(145, 216, 174, 1) 0%, rgba(145, 216, 174, 0) 100%);

  @media (--viewportMedium) {
    /* Safari */
    background: -webkit-linear-gradient(90deg, rgba(145, 216, 174, 1) 0%, rgba(145, 216, 174, 0) 60%);
    /* Firefox */
    background: -moz-linear-gradient(90deg, rgba(145, 216, 174, 1) 0%, rgba(145, 216, 174, 0) 60%);
    /* Opera */
    background: -o-linear-gradient(90deg, rgba(145, 216, 174, 1) 0%, rgba(145, 216, 174, 0) 60%);
    /* Standard */
    background: linear-gradient(90deg, rgba(145, 216, 174, 1) 0%, rgba(145, 216, 174, 0) 60%);
    /* background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 60%); */
  }
}

.title {
  composes: h2 from global;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 24px;
  /* color: var(--matterColorDark); */
  color: var(--matterColorLight);

  @media (--viewportMedium) {
    text-align: left;
  }

  @media (--viewportLarge) {
    text-align: left;
    font-size: 23px;
  }

  @media (--viewportLargeWithPaddings) {
    font-size: 28px;
  }

  @media (--viewportXLarge) {
    font-size: 32px;
  }
}

.threeUuperButton {
  composes: button buttonFont buttonText buttonBorders from global;
  margin-bottom: 24px;
  border-radius: 10px;
  background-color: var(--successColor);
  color: var(--matterColorLight);
  margin-top: 2rem;
  font-size: 14px;
  border: 2px solid var(--matterColorLight);

  @media (--viewportMedium) {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    font-size: 16px;
  }
}

.threeUuperButton:focus,
.threeUuperButton:hover {
  background-color: var(--successColorDark);
  color: var(--matterColorLight);
  border: 2px solid var(--matterColorLight);
}

.triangulo {
  position: absolute;
  left: -4rem;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 60px solid #c5e5c980;
  border-top: 130px solid transparent;
  border-bottom: 130px solid transparent;

  @media (--viewportMedium) {}

  @media (--viewportLarge) {
    border-left: 100px solid #c5e5c980;
    border-top: 130px solid transparent;
    border-bottom: 130px solid transparent;
  }

  @media (--viewportXLarge) {
    border-left: 100px solid #c5e5c980;
    border-top: 180px solid transparent;
    border-bottom: 180px solid transparent;
  }
}