@import '../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 11px 24px 0 24px;
  @media (--viewportMedium){
    padding: 11px 84px 0 84px;
  }
}

.form {
  flex-grow: 1;
}

.title {
  margin-bottom: 19px;
  color: var(--matterColorDark);
  font-size: 18px;
  font-weight: var(--Med--fontWeightMediumhtSemiBold);
  @media (--viewportMedium) {
    font-size: 30px;
  }
  @media (--viewportXLarge) {
    margin-bottom: 17px;
    padding: 1px 0 7px 0;
    font-size: 40px;
    line-height: 56px;
    letter-spacing: -1.25px;
  }
}

.subtitle {
  font-size: 14px;
}
