@import '../../styles/customMediaQueries.css';

.root {
  & p {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 12px;
    margin-bottom: 12px;

    @media (--viewportMedium) {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 17px;
      margin-bottom: 15px;
    }
  }

  & h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 29px 0 13px 0;

    @media (--viewportMedium) {
      margin: 32px 0 0 0;
    }
  }
}

.lastUpdated {
  composes: marketplaceBodyFontStyles from global;
  margin-top: 0;
  margin-bottom: 55px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 54px;
  }
}

.titleRefund {
  font-weight: var(--Med--fontWeightMediumhtSemiBold);
}

.listRefund {
  list-style: disc;
  padding-left: 18px;
}

.listRefund2 {
  padding-left: 18px;
}

.listRefund li,
.listRefund2 li {
  font-size: 14px;
  margin-bottom: 24px;
  line-height: 1.6;
}

.textItalic {
  font-style: italic;
}