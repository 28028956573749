@import '../../styles/customMediaQueries.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }

  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 3s;

  visibility: hidden;
  opacity: 1;
  position: relative;
}


.uuperContent {
  @media (min-width: 768px) {
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(215, 215, 215, 1) 50%, rgba(215, 215, 215, 1) 100%);
  }
}

.containerUuper {
  max-width: 1824px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;

  @media (--viewportLarge) {
    flex-wrap: nowrap;
  }
}

.contentImgUuper {
  position: relative;
  flex-basis: 100%;
  padding: 0 3rem;
  text-align: center;

  @media (--viewportLarge) {
    text-align: end;
    flex: 1;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 0 3rem;
  }
}

.image {
  width: 80%;

  @media (--viewportSmall) {
    width: 60%;
  }

  @media (--viewportMedium) {
    width: 90%;
  }
}

.contentTextUuper {
  flex-basis: 100%;
  padding: 2rem 4rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(215, 215, 215, 1) 50%, rgba(215, 215, 215, 1) 100%);

  @media (--viewportLarge) {
    padding: 10rem 2rem 12rem 0;
    flex: 1;
    background: none;
  }
}

.titleContainer {
  @media (--viewportLarge) {
    padding: 0 3rem;
  }

  @media (min-width: 1800px) {
    padding: 0 6rem;
  }
}

.titleOneUuper {
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--matterColorDark);
  margin: 1.5rem 0;

  @media (--viewportLargeWithPaddings) {
    font-size: 40px;
  }
}

.subtitleOneUuper {
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 4.9px;
  color: var(--matterColorDark);

  @media (min-width: 1800px) {
    font-size: 20px;
    letter-spacing: 7px;
    line-height: 28px;
  }
}

.subtitleOneUuperStrong {
  composes: subtitleOneUuper;
  font-weight: 800;
  font-size: 16px;
  padding-left: unset;

  @media (min-width: 1800px) {
    font-size: 20px;
  }
}

.textOneUuper {
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: var(--matterColorDark);
  margin: 1rem 0;

  @media (--viewportLarge) {
    padding: 0 3rem;
  }

  @media (--viewportLargeWithPaddings) {
    font-size: 16px;
  }

  @media (min-width: 1800px) {
    padding: 0 6rem;
    font-size: 18px;
    line-height: 140%;
  }
}

.subtextOneUuper {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: var(--matterColorDark);
  margin: 1rem 0;

  @media (--viewportLargeWithPaddings) {
    font-size: 16px;
  }

  @media (min-width: 1800px) {
    font-size: 20px;
    line-height: normal;
  }
}

.listOneUuper {
  list-style: disc;
  padding-left: 1.3rem;
}

.heroUuperButton {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;
  composes: animation;
  margin-bottom: 24px;
  border-radius: 10px;

  @media (--viewportLarge) {
    display: block;
    margin-right: 2rem;
  }
}

.heroUuperButton:focus,
.heroUuperButton:hover {
  background-color: var(--successColorDark);
  color: #fff;
}

.heroUuperButtonFEDelay {
  animation-delay: 0.3s;
}

.contentButton {
  margin-top: 2rem;

  @media (--viewportLarge) {
    padding-left: 3rem;
  }

  @media (min-width: 1800px) {
    padding-left: 6rem;
  }
}

.ctaButton {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;
  margin: 12px auto;
}

.ctaButton:focus,
.ctaButton:hover {
  background-color: var(--successColorDark);
}

.ctaButtonDark {
  composes: ctaButton;
  background-color: var(--marketplaceColorDark);
}


.topButtonContainer {
  background-color: var(--marketplaceColor);
  /* margin-top: -12px; */
  text-align: center;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 20px;
  padding-right: 20px;

  @media (--viewportMedium) {
    padding-left: unset;
    padding-right: unset;
  }
}