@import '../../styles/customMediaQueries.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }

  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 3s;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.twoUuperContent {
  max-width: 1824px;
  margin: auto;
  padding: 4% 28px;
}

.containerTitle {
  text-align: center;
}

.title {
  composes: h1 from global;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 4.9px;
  margin-bottom: 24px;
  font-weight: 400;
  color: var(--matterColorDark);
  @media (--viewportXLarge) {
    font-size: 20px;
    letter-spacing: 7px;
  }
}

.subtitle {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: normal;
  padding-bottom: 5rem;
  color: var(--matterColorDark);
  @media (--viewportMedium) {
    font-size: 24px;
    line-height: 1.33;
  }
  @media (--viewportLarge) {
    font-size: 35px;
  }
  @media (--viewportXLarge) {
    font-size: 40px;
    line-height: normal;
  }
}

.line {
  display: flex;
  width: 95%;
  height: 25px;
  margin: auto;
  background-color: var(--marketplaceColor);
  margin-top: -23px;
  @media (--viewportSmall) {
    width: 75%;
  }
  @media (--viewportMedium) {
    width: 45%;
  }
  @media (--viewportLarge) {
    width: 25%;
  }
}

.triangulo {
  width: 0;
  height: 0;
  border-right: 20px solid transparent;
  border-top: 20px solid transparent;
  border-left: 20px solid transparent;
  border-bottom: 20px solid var(--marketplaceColor);
  margin: auto;
  margin-bottom: 2rem;
}

.sectionBackground {
  padding-bottom: 7%;
}

.containerCards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 27px;
  @media (--viewportLarge) {
    flex-direction: row;
    gap: 6%;
  }
}

.contentCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 230px;
  height: 230px;
  font-size: 14px;
  padding: 3rem 3rem;
  line-height: normal;
  letter-spacing: normal;
  border-radius: 10px;
  box-shadow: 5px 9px 13px 0 rgba(0, 0, 0, 0.25);
  font-weight: 400;
  color: var(--matterColorDark);
  @media (--viewportSmall) {
    width: 65%;
  }
  @media (--viewportLarge) {
    width: 300px;
    height: 300px;
    padding: 3rem 2rem;
  }
  @media (--viewportLargeWithPaddings) {
    width: 350px;
    height: 350px;
    padding: 1rem 3rem;
  }
  @media (--viewportLarge) {
    font-size: 18px;
    font-weight: 400;
    line-height: 140%;
  }
}

.image {
  width: 30%;
  margin-bottom: 2rem;
  @media (--viewportLargeWithPaddings) {
    width: 50%;
  }
}
