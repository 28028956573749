@import '../../styles/customMediaQueries.css';

.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}

.features {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin-top: 25px;
  margin-bottom: 24px;
  flex-shrink: 0;
  width: 100%;
  letter-spacing: -0.27px;
  text-transform: none;
  font-weight: var(--Med--fontWeightMediumhtSemiBold);
  background-color: var(--matterColorDark);
  @media (--viewportLarge) {
    display: inline-block;
    width: 50%;
  }
}

.submitButton:focus,
.submitButton:hover {
  background-color: var(--successColorDark);
  color: var(--matterColorLight);
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--matterColorDark);
}

.textPlace {
  composes: marketplacetextPlace from global;
}

.textHelp {
  background-color: #f5f5f5;
  display: flex;
  align-items: flex-start;
  padding: 8px;
}

.textHelp span {
  font-size: 12px;
  font-weight: var(--fontWeightRegular);
  line-height: 16.8px;
}

.imageIcon {
  margin-right: .5rem;
}
