@import '../../styles/customMediaQueries.css';

.contactContent {
  background: url('../../assets/maniqui.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.content {
  padding: 4rem 0rem;
  display: flex;
  flex-wrap: wrap;
  max-width: 1824px;
  margin: auto;
  gap: 30px;
  text-align: center;

  @media (--viewportMedium) {
    padding: 8rem 6rem;
    flex-wrap: nowrap;
  }
}

.firstColumn {
  flex-basis: 100%;
  padding: 1rem 2rem;

  @media (--viewportSmall) {
    margin: 1rem 2.5rem;
  }

  @media (--viewportMedium) {
    margin: 0;
    flex: 1;
  }
}

.formColumn {
  flex-basis: 100%;
  color: #fff;
  font-weight: 500;
  padding: 1rem 2rem;

  @media (--viewportSmall) {
    margin: 1rem 2.5rem;
  }

  @media (--viewportMedium) {
    margin: 0;
    flex: 1;
  }

  @media (--viewportLarge) {
    padding: 0 2rem;
  }
}

.title {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 24px;
  text-align: center;
  color: var(--matterColorLight);

  @media (--viewportLargeWithPaddings) {
    font-size: 40px;
  }

  @media (--viewportXLarge) {
    font-size: 40px;
  }
}

.text {
  color: var(--matterColorLight);
  line-height: normal;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;

  @media (--viewportLarge) {
    font-size: 18px;
  }

  @media (--viewportXLarge) {
    font-size: 18px;
    line-height: normal;
  }
}

.buttonForm {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;
  margin-bottom: 24px;
  border-radius: 10px;
  color: #fff;
  margin-top: 2rem;

  @media (--viewportMedium) {
    margin-left: auto;
  }
}

.label {
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.inputForm {
  border-bottom-color: #fff;
}

.inputForm:hover {
  border-bottom-color: #92c198;
}